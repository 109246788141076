import React from 'react'
import { Link } from 'gatsby'
import Counter from './counter'
import Image from '../image/bg-image'
import './home-block.sass'

const HomeBlock = ({ ...props }) => (
  <article className={`HomeBlock ${props.i % 2 ? 'HomeBlock--even' : 'HomeBlock--odd'}`}>
    <Link className="HomeBlock__link" to={props.data.url}>
      <div className="HomeBlock__media">
        <Image src={props.data.thumb.url} small={true} />
      </div>
      <div className="HomeBlock__content">
        <div>
          <h1 className="HomeBlock__title">
            {props.data.name_eng}<br />
            <span>{props.data.name_jap}</span>
          </h1>
        </div>
      </div>
    </Link>
    <Counter
      className="HomeBlock__counter"
      count={props.i + 1}
      job={props.data.job_title}
      total={props.total} />
  </article>
)

export default HomeBlock
