import React from 'react'
import './counter.sass'

const Counter = ({ className, count, total, job }) => (
  <div className={`Counter ${className}`}>
    <div className="Counter__number Counter__number--top">
      {count}
    </div>
    <div className="Counter__line">
      {job}
    </div>
    <div className="Counter__number Counter__number--bottom">
      {total}
    </div>
  </div>
)

export default Counter
