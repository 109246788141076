import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import './bg-image.sass'

class LazyImage extends Component {

  constructor(props) {
    super(props)
    this.state = {
      imageLoaded: false,
    }
  }

  componentDidMount() {
    this.lazyLoadImage()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.url !== this.props.url) {
      this.setState({ imageLoaded: false })
      this.lazyLoadImage()
    }
  }

  lazyLoadImage() {
    if (typeof Image !== 'undefined') {
      let img = new Image()
      img.onload = () => this.setState({ imageLoaded: true })
      img.src = this.props.src
    }
  }

  render() {
    return (
      <div className={`LazyImage ${this.props.small ? 'LazyImage--small' : ''}`}>
        <CSSTransition
          in={this.state.imageLoaded}
          unmountOnExit
          timeout={800}
          classNames="a-image">
          <div
            className="LazyImage__image"
            style={{
              backgroundImage: `url(${this.props.src})`
            }} />
        </CSSTransition>
      </div>
    )
  }

}

export default LazyImage
