import React from 'react'
import Seo from '../components/seo/seo'
import { graphql } from 'gatsby'
import Rellax from 'react-rellax'
import Layout from '../components/layouts/layout'
import HomeBlock from '../components/home-block/home-block'
import siteBg from '../images/site-bg.svg'
import './index.sass'

const getBlocks = (items) => {
  return items.map((item, i) => {
    return (
      <HomeBlock
        key={i}
        data={item.node.frontmatter}
        i={i}
        total={items.length} />
    )
  })
}

const IndexPage = ({ data }) => (
  <Layout className="Index">
    <Rellax speed={-4}>
      <img
        src={siteBg}
        alt="Japanese characters used as a background"
        className="Index__bg" />
    </Rellax>
    <Seo />
    <div className="Index__hero">
      <div className="u-wrap">
        <div className="Index__hero-wrap">
          <div className="Vertical">
            <span className="Vertical__text">
              Future <span className="Vertical__spacer" />artists
            </span>
            <span className="Vertical__text">
              From <span className="Vertical__spacer" />Japan
            </span>
          </div>
          <h1 className="Index__hero__title">
            <span>A creative</span> <span>
            talent house, curated</span> <span className="Index__hero__flex">
            by Nexus Studios.
            <div className="Index__hero__subtitle">
              Specialising in contemporary Japanese animation and design.
            </div>
            </span>
          </h1>
        </div>
      </div>
    </div>
    {getBlocks(data.allMarkdownRemark.edges)}
  </Layout>
)

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex : "/content/artists/" },
      },
      sort: {
        fields: [frontmatter___order],
        order: ASC
      }
    ) {
      edges {
        node {
          frontmatter {
            name_eng
            name_jap
            job_title
            url
            thumb {
              url
            }
          }
        }
      }
    }
  }
`

export default IndexPage
